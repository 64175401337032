import * as Sentry from "@sentry/browser";

/**
 * Retrieves the Segment context, which contains information about the user's operating system and device type.
 *
 * The function first checks if `navigator.userAgentData` is available, which is a new, privacy-preserving
 * way to get user-agent data and is currently supported in browsers like Chrome and Edge.
 *
 * If `navigator.userAgentData` is unavailable (like in Firefox or Safari) or if fetching high entropy
 * values fails, it falls back to the OS data provided from the backend.
 *
 * @returns {Object} - The context containing OS and device information.
 */
async function getSegmentContext() {
  let osInfo = {};

  if (typeof window.navigator.userAgentData === "object") {
    try {
      // Fetch high entropy platform version.
      const ua = await navigator.userAgentData.getHighEntropyValues(["platformVersion"]);
      osInfo.version = ua["platformVersion"];
      osInfo.name = window.navigator.userAgentData.platform;
      osInfo.mobile = window.navigator.userAgentData.mobile;
    } catch (error) {
      // In case of any errors, fallback to the OS data from the backend.
      console.error(error);
      osInfo = window.segmentOsContext || {};
    }
  } else {
    // Fallback to the OS data from the backend if `navigator.userAgentData` is unsupported.
    osInfo = window.segmentOsContext || {};
  }

  // We are usurping the standard device.type property to be either "Mobile" or "Desktop".
  let context = { os: osInfo, device: { type: osInfo.mobile ? "Mobile" : "Desktop" } };

  if (window.funeralId) {
    context.groupId = window.funeralId;
  } else if (window.petServiceId) {
    context.groupId = window.petServiceId;
  }

  return context;
}

/**
 * Sends a tracking event to Segment.
 *
 * @param {string} env - The environment where the event is being tracked, e.g., "Nok" for the NOK dashboard.
 * @param {string} title - A label for the event, like a title from the element it is sent from.
 * @param {string} action - The specific action that occurred, like "Click" or "Submit".
 * @param {Object|null} [properties=null] - Optional additional properties to send with the event.
 */
async function sendSegmentAnalytics(env, title, action, properties = null) {
  try {
    const context = await window.getSegmentContext();
    const combinedProperties = properties
      ? { ...window.segmentFeProperties, ...properties }
      : window.segmentFeProperties;

    window.analytics.track(`${env} ${title} ${action}`, combinedProperties, context);
  } catch (error) {
    console.error("Error sending analytics:", error);
  }
}

function initSentry() {
  const urlParts = window.location.pathname.split("/").filter(Boolean);
  const uuid = urlParts[urlParts.length - 1];

  const config = {
    dsn: process.env.FE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Performance Monitoring
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    initialScope: {
      user: {
        uuid: uuid,
      },
    },
  };

  if (process.env.NODE_ENV !== "development") {
    Sentry.init(config);
  }
}

function throwCustomSentryError(name, error, options = {}) {
  Sentry.configureScope((scope) => {
    const currentUser = scope.getUser();
    const updatedUser = { ...currentUser, ...options };
    scope.setUser(updatedUser);
  });

  const sentryError = new Error(error);
  sentryError.name = name;
  Sentry.captureException(sentryError);
}

// Expose or call functions globally.
window.getSegmentContext = getSegmentContext;
window.sendSegmentAnalytics = sendSegmentAnalytics;
window.throwCustomSentryError = throwCustomSentryError;
initSentry();
